import "../../../../helpers/popups.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";

function SendCopy() {
  return (
    <Popup
      trigger={<button className="button">Send Me A Copy</button>}
      modal
      nested
    >
      {(close) => (
        <div className="modal">
          <button className="close" onClick={close}>
            &times;
          </button>
          <div className="header">
            Please fill out this form so we know where to send your
            configuration and a representative will reach out to you soon!
          </div>
          <div className="content">
            {" "}
            <form className="contact-form-container">
              <input
                className="contact-form-input"
                type="text"
                name="name"
                id="name"
                value="NAME"
              />
              <input
                className="contact-form-input"
                type="text"
                name="email"
                id="email"
                value="EMAIL"
              />
              <ButtonSubmit />
            </form>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default SendCopy;
