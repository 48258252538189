export const MenuItems = [
  {
    title: "31' Targa",
    path: "/31targa",
    cName: "dropdown-link",
  },
  {
    title: "35' HT",
    path: "/35ht",
    cName: "dropdown-link",
  },
  {
    title: "41' HT",
    path: "/41ht",
    cName: "dropdown-link",
  },
  {
    title: "54' Fly",
    path: "/54fly",
    cName: "dropdown-link",
  },
];
