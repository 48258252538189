import "./ButtonLight.css";
import { Link } from "react-router-dom";
import BtnArrowLight from "../../media/img/btn-arrow-light.svg";

function ButtonHome() {
  return (
    <Link to="/">
      <a className="button ButtonHome" href="#home">
        GO TO HOME
        <img className="arrow" src={BtnArrowLight} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonHome;
