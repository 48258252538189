import "./ButtonLight.css";
import { Link } from "react-router-dom";

function ButtonGoBackOne() {
  return (
    <Link to="/build">
      <a className="button ButtonGoBackOne" href="#build">
        GO BACK
      </a>
    </Link>
  );
}

export default ButtonGoBackOne;
