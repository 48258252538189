import "./ButtonDark.css";
import { Link } from "react-router-dom";
import BtnArrowDark from "../../media/img/btn-arrow-dark.svg";

function ButtonFooter() {
  return (
    <Link to="/build">
      <a className="button ButtonFooter" href="#build">
        CONFIGURE
        <img className="arrow" src={BtnArrowDark} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonFooter;
