import "./AboutSections.css";
import image from "../../../media/img/about-photo-1.webp";

function Intro() {
  return (
    <section className="about-container">
      <div className="about-intro-container">
        <img className="about-intro-image" src={image} alt="img"></img>
        <h4 className="about-intro-quote black">
          “Our vessels apart from being luxurious, feature State of the Art
          technology to provide unique experiences with great comfort on board.”
        </h4>
      </div>
    </section>
  );
}

export default Intro;
