import "./ButtonLight.css";
import { Link } from "react-router-dom";
import BtnArrowLight from "../../media/img/btn-arrow-light.svg";

function ButtonFeatured35() {
  return (
    <Link to="/35ht">
      <a className="button ButtonFeatured" href="#35ht">
        INFO
        <img className="arrow" src={BtnArrowLight} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonFeatured35;
