import "./News.css";
import article_1 from "../../media/img/landing-news-1.png";
import article_2 from "../../media/img/landing-news-2.png";
import article_3 from "../../media/img/landing-news-3.png";

function News() {
  return (
    <section className="news-hero bg">
      <h3 className="news-hero-title">Latest Updates & News</h3>
      <div className="news-articles-container">
        <div className="news-article">
          <p className="news-article-date body-text-lg">AUG 11, 2021</p>
          <a href="#article1">
            <img className="news-article-image" src={article_1} alt="img"></img>
          </a>
          <p className="news-article-title body-text-lg">31' Targa</p>
          <p className="news-article-description body-text-lg">
            Tethys Yachts entrega lancha de 31 pés com targa em Capitólio
          </p>
        </div>
        <div className="news-article">
          <p className="news-article-date body-text-lg">AUG 11, 2021</p>
          <a href="#article2">
            <img className="news-article-image" src={article_2} alt="img"></img>
          </a>
          <p className="news-article-title body-text-lg">31' Targa</p>
          <p className="news-article-description body-text-lg">
            Tethys Yachts entrega lancha de 31 pés com targa em Capitólio
          </p>
        </div>
        <div className="news-article">
          <p className="news-article-date body-text-lg">AUG 11, 2021</p>
          <a href="#article3">
            <img className="news-article-image" src={article_3} alt="img"></img>
          </a>
          <p className="news-article-title body-text-lg">41' HT</p>
          <p className="news-article-description body-text-lg">
            Tethys Yachts entrega lancha de 31 pés com targa em Capitólio
          </p>
        </div>
      </div>
    </section>
  );
}

export default News;
