import "./HeroModel.css";
import hero35 from "../../../media/img/hero35.png";
import ButtonBuild35 from "../../Buttons/ButtonBuild35";

function Hero35() {
  return (
    <section className="model-hero bg">
      <div className="model-hero-intro">
        <h1 className="model-hero-title">35’ HT</h1>
        <img className="model-hero-image" src={hero35} alt="img"></img>
        <h5 className="model-hero-description">
          The Tethys 41 HT with its sporty and bold design, is 12.9 meters long,
          comes complete with a submersible transom platform, gourmet space with
          a barbecue grill, sophisticated internal finishings and much more. Its
          highlight is the cockpit lounge, it's comfort and fully equipped
          kitchen. It offers great visibility at the helm and is superior at
          navigation.
        </h5>
        <ButtonBuild35 />
      </div>
    </section>
  );
}

export default Hero35;
