import "./Dealers.css";
import photo_1 from "../../media/img/landing-dealer-1.png";
import photo_2 from "../../media/img/landing-dealer-2.jpg";
import photo_3 from "../../media/img/landing-dealer-3.png";
import photo_4 from "../../media/img/landing-dealer-4.png";
import photo_5 from "../../media/img/landing-dealer-5.png";
import photo_6 from "../../media/img/landing-dealer-6.png";
import Select from "react-select";

function Dealers() {
  const options = [
    { value: "USA", label: "United States" },
    { value: "Australia", label: "Australia" },
    { value: "Brazil", label: "Brazil" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Singapore", label: "Singapore" },
    { value: "Turkey", label: "Turkey" },
  ];
  return (
    <section className="dealer-hero">
      <div className="dealer-content">
        <h3 className="dealer-title">Find a dealer</h3>
        <p className="dealer-subtitle body-text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium
          platea lorem orci ullamcorper nisi ullamcorper fermentum, erat.
        </p>
        <Select
          className="dealer-dropdown"
          placeholder="Choose your country"
          options={options}
          isSearchable={false}
          styles={{
            dropdownIndicator: (provided, state) => ({
              ...provided,
              transition: "all .2s ease",
              transform: state.selectProps.menuIsOpen && "rotate(180deg)"
            }),
          }}
        />
        <img className="photo-7" src={photo_1} alt="img" />
      </div>
      <img className="photo-1" src={photo_1} alt="img" />
      <img className="photo-2" src={photo_2} alt="img" />
      <img className="photo-3" src={photo_3} alt="img" />
      <img className="photo-4" src={photo_4} alt="img" />
      <img className="photo-5" src={photo_5} alt="img" />
      <img className="photo-6" src={photo_6} alt="img" />
    </section>
  );
}

export default Dealers;
