import "./ButtonLight.css";
import { Link } from "react-router-dom";
import BtnArrowLight from "../../media/img/btn-arrow-light.svg";

function ButtonBuildYachtModels() {
  return (
    <Link to="/build">
      <a className="button ButtonBuildYachtModels" href="#build">
        CONFIGURE
        <img className="arrow" src={BtnArrowLight} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonBuildYachtModels;
