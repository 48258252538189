import "./Questions.css";
import ButtonContact from "../Buttons/ButtonContact";

function Questions() {
  return (
    <section className="questions-hero">
      <div className="questions-container">
        <h3 className="questions-hero-title">Do you have any questions?</h3>
        <ButtonContact />
      </div>
    </section>
  );
}

export default Questions;
