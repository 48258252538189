import Nav from "../components/Nav/NavBar/NavBar";
import Hero from "../components/Heros/Build/HeroBuild";
import Footer from "../components/Footer/Footer";

function Build() {
  return (
    <body>
      <Nav />
      <Hero />
      <Footer />
    </body>
  );
}

export default Build;
