import "./Form.css";
import ButtonSubmit from "../Buttons/ButtonSubmit";

function Form() {
  return (
    <section className="contact-hero">
      <h4 className="contact-form-instructions">
        Please fill out this form and a representative will reach out to you
        soon!
      </h4>
      <form className="contact-form-container">
        <input
          className="contact-form-input"
          type="text"
          name="fname"
          placeholder="FIRST NAME"
          minlength="2"
          required
          autoFocus
        />
        <input
          className="contact-form-input"
          type="text"
          name="lname"
          placeholder="LAST NAME"
          minlength="2"
          required
        />
        <input
          className="contact-form-input"
          type="email"
          name="email"
          placeholder="EMAIL"
          minlength="2"
          required
        />
        <input
          className="contact-form-input"
          type="tel"
          name="phone"
          placeholder="PHONE"
          maxlength="10"
          required
        />
        <ButtonSubmit type="submit" value="Submit" />
      </form>
    </section>
  );
}

export default Form;
