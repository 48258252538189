import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Burger from "./components/Nav/Burger/NavBarBurger";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Build from "./pages/Build";
import Targa31 from "./pages/Targa31";
import HT35 from "./pages/HT35";
import HT41 from "./pages/HT41";
import Fly54 from "./pages/Fly54";
import Step1 from "./pages/Step1";
import Step2 from "./pages/Step2";
import Step3 from "./pages/Step3";
import Overview from "./pages/Overview";
import Australia from "./components/Dealers/Popups/Australia";
import USA from "./components/Dealers/Popups/USA";
import Kuwait from "./components/Dealers/Popups/Kuwait";
import Singapore from "./components/Dealers/Popups/Singapore";
import Brazil from "./components/Dealers/Popups/Brazil";
import Turkey from "./components/Dealers/Popups/Turkey";
import SendCopy from "./components/Config/Overview/Popups/SendCopy";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import "./helpers/typography.css";
import "./helpers/colors.css";
import "./helpers/popups.css";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/burger" component={Burger} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/build" component={Build} />
        <Route path="/31targa" component={Targa31} />
        <Route path="/35ht" component={HT35} />
        <Route path="/41ht" component={HT41} />
        <Route path="/54fly" component={Fly54} />
        <Route path="/dealers/usa" component={USA} />
        <Route path="/dealers/australia" component={Australia} />
        <Route path="/dealers/kuwait" component={Kuwait} />
        <Route path="/dealers/turkey" component={Turkey} />
        <Route path="/dealers/brazil" component={Brazil} />
        <Route path="/dealers/singapore" component={Singapore} />
        <Route path="/config/31targa/step1" component={Step1} />
        <Route path="/config/31targa/step2" component={Step2} />
        <Route path="/config/31targa/step3" component={Step3} />
        <Route path="/config/31targa/overview" component={Overview} />
        <Route path="/config/31targa/overview/export" component={SendCopy} />
        <Route path="/config/35ht/step1" component={Step1} />
        <Route path="/config/35ht/step2" component={Step2} />
        <Route path="/config/35ht/step3" component={Step3} />
        <Route path="/config/35ht/overview" component={Overview} />
        <Route path="/config/35ht/overview/export" component={SendCopy} />
        <Route path="/config/41ht/step1" component={Step1} />
        <Route path="/config/41ht/step2" component={Step2} />
        <Route path="/config/41ht/step3" component={Step3} />
        <Route path="/config/41ht/overview" component={Overview} />
        <Route path="/config/41ht/overview/export" component={SendCopy} />
        <Route path="/config/54fly/step1" component={Step1} />
        <Route path="/config/54fly/step2" component={Step2} />
        <Route path="/config/54fly/step3" component={Step3} />
        <Route path="/config/54fly/overview" component={Overview} />
        <Route path="/config/54fly/overview/export" component={SendCopy} />
        <Route path="/coming-soon" component={ComingSoon} />
      </Switch>
    </Router>
  );
}

export default App;
