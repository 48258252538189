import "./ButtonDark.css";
import { Link } from "react-router-dom";

function ButtonGoBackOverview() {
  return (
    <Link to="/config/41ht/step3">
      <a className="button ButtonGoBackOverview" href="#config/41ht/step3">
        GO BACK
      </a>
    </Link>
  );
}

export default ButtonGoBackOverview;
