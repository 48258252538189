import "./AboutSections.css";
import image from "../../../media/img/about-photo-4.png";


function Research() {
  return (
    <section className="about-container">
      <div className="about-research-container">
        <h2 className="about-research-title">Our Research</h2>
        <img
          className="about-research-image"
          src={image}
          alt="img"
        ></img>
        <p className="about-research-paragraph body-text-lg">
          We strive on improving the integration of our onboard spaces,
          empowering our customers the thrill of enjoyment and satisfaction
          while sailing, providing comfort and tranquility for those that are
          truly taking advantage of the best moments life can offer at sea.
        </p>
      </div>
    </section>
  );
}

export default Research;
