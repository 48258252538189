import "./HeroContact.css";
import SliderContact from "../../Slider/SliderContact";

function HeroContact() {
  return (
    <section className="contact-hero">
      <h1 className="contact-hero-title black">Contact Us</h1>
      <SliderContact/>
    </section>
  );
}

export default HeroContact;
