import React from "react";
import ReactPlayer from "react-player";
import Video from "../../media/video/video31.mp4";
import "./Video.css";

function Video31() {
  return (
    <section>
      <ReactPlayer
        url={Video}
        width="100vw"
        height="70vh"
        loop="true"
        playing="true"
        volume="0"
      />
    </section>
  );
}

export default Video31;
