import Nav from "../components/Nav/NavBar/NavBar";
import Hero from "../components/Heros/About/HeroAbout";
import Intro from "../components/About/Sections/Intro";
import Personas from "../components/About/Sections/Personas";
import Info from "../components/About/Sections/Info";
import Video from "../components/Video/VideoAbout";
import ABYC from "../components/About/Sections/ABYC";
import Research from "../components/About/Sections/Research";
import CE from "../components/About/Sections/CE";
import Footer from "../components/Footer/Footer";

function About() {
  return (
    <body>
      <Nav />
      <Hero />
      <Intro />
      <Personas />
      <Info />
      <Video />
      <ABYC />
      <Research />
      <CE />
      <Footer />
    </body>
  );
}

export default About;
