import "./Equipment.css";
import ButtonGoBackThree from "../../Buttons/ButtonGoBackThree";
import ButtonNextStepOverview from "../../Buttons/ButtonNextStepThree";
import option from "../../../media/img/equipment-option.svg";
import ButtonShowMore from "../../Buttons/ButtonShowMore";

function Equipment() {
  return (
    <section>
      <div className="equipment-options-container">
        <h2 className="equipment-options-title-adaptive">
          STEP 3:<br></br>Equipment Options
        </h2>
        <div className="option-type-container">
          <div className="option-type-option is-active-option">
            <span className="option-type-title is-active-option">Cabin</span>
          </div>
          <div className="option-type-option">
            <span className="option-type-title">Deck</span>
          </div>
          <div className="option-type-option">
            <span className="option-type-title">Cockpit</span>
          </div>
          <div className="option-type-option">
            <span className="option-type-title">Helm</span>
          </div>
          <div className="option-type-option">
            <span className="option-type-title">Bow</span>
          </div>
        </div>
        <div className="options-container">
          <div className="option-container">
            <p className="option-title">AIR CONDITIONER</p>
            <img className="option-img" src={option} alt="img"></img>
            <p className="option-price">$3,540.00</p>
          </div>
          <div className="option-container">
            <p className="option-title">BOILER</p>
            <img className="option-img" src={option} alt="img"></img>
            <p className="option-price">$1,047.00</p>
          </div>
          <div className="option-container">
            <p className="option-title">CHARCOAL BARBECUE</p>
            <img className="option-img" src={option} alt="img"></img>
            <p className="option-price">$695.00</p>
          </div>
          <div className="option-container">
            <p className="option-title">ELECTRIC GRILL</p>
            <img className="option-img" src={option} alt="img"></img>
            <p className="option-price">$779.00</p>
          </div>
          <div className="option-container">
            <p className="option-title">MICROWAVE OVEN</p>
            <img className="option-img" src={option} alt="img"></img>
            <p className="option-price">$846.00</p>
          </div>
          <div className="option-container">
            <p className="option-title">JOYSTICK</p>
            <img className="option-img" src={option} alt="img"></img>
            <p className="option-price">$14,471.00</p>
          </div>
        </div>
        <div className="equipment-options-button-container">
          <ButtonShowMore />
        </div>
        <div className="equipment-options-buttons-container">
          <ButtonGoBackThree />
          <ButtonNextStepOverview />
        </div>
      </div>
    </section>
  );
}

export default Equipment;
