import "./ButtonDark.css";
import { Link } from "react-router-dom";

function ButtonNextStepTwo() {
  return (
    <Link to="/config/41ht/step3">
      <a className="button ButtonNextStepTwo" href="#config/41ht/step3">
        NEXT STEP
      </a>
    </Link>
  );
}

export default ButtonNextStepTwo;
