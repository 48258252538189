import React, { useState } from "react";
import { Languages } from "../MenuItems/Languages";
import "./Dropdown.css";
import { Link } from "react-router-dom";

function LanguageSelector() {
  const [click, setClick] = useState(false); 
  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu-language clicked" : "dropdown-menu-language"}
      >
        {Languages.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default LanguageSelector;
