import "./ButtonDark.css";
import { Link } from "react-router-dom";

function ButtonNextStepThree() {
  return (
    <Link to="/config/41ht/overview">
      <a className="button ButtonNextStepThree" href="#config/41ht/overview">
        NEXT STEP
      </a>
    </Link>
  );
}

export default ButtonNextStepThree;
