import "./Engine.css";
import ButtonGoBackTwo from "../../Buttons/ButtonGoBackTwo";
import ButtonNextStepThree from "../../Buttons/ButtonNextStepTwo";
import fuel_type from "../../../media/img/fuel-type.svg";
import engine_package from "../../../media/img/engine-package.svg";

function EngineOptions() {
  return (
    <section>
      <div className="engine-options-container">
        <h2 className="engine-options-title-adaptive">
          STEP 2:<br></br>Engine Options
        </h2>
        <img src={fuel_type} alt="img" className="step-banner"></img>
        <h2 className="engine-options-step-title body-text-lg">Step 1: Fuel Type</h2>
        <div className="fuel-type-container">
          <div className="fuel-type-option is-active-option">
            <span className="fuel-type-title is-active-option">Gas</span>
          </div>
          <div className="fuel-type-option">
            <span className="fuel-type-title">Diesel</span>
          </div>
        </div>
        <img src={engine_package} alt="img" className="step-banner"></img>
        <h2 className="engine-options-step-title body-text-lg">Step 2: Engine Package</h2>
        <div className="engine-type-table">
          <ul className="engine-type-table-row">
            <div className="engine-option-select"></div>
            <li className="engine-type-table-text model">
              <span>Mercury 8.2L DTS | Bravo 3<br></br>380 HP</span>
            </li>
            <li className="engine-type-table-text price"><span>$26,716.00</span></li>
          </ul>
          <ul className="engine-type-table-row">
            <div className="engine-option-select"></div>
            <li className="engine-type-table-text model">
            <span>Mercury 4.5L DTS | Bravo 3<br></br>250 HP</span>
            </li>
            <li className="engine-type-table-text price"><span>$37,180.00</span></li>
          </ul>
          <ul className="engine-type-table-row">
            <div className="engine-option-select"></div>
            <li className="engine-type-table-text model">
            <span>Mercury AXIUS 4.5L DTS | Bravo 3<br></br>250 HP</span>
            </li>
            <li className="engine-type-table-text price"><span>$42,935.00</span></li>
          </ul>
          <ul className="engine-type-table-row">
            <div className="engine-option-select"></div>
            <li className="engine-type-table-text model">
            <span>Mercury 4.5L DTS | Bravo 3<br></br>300 HP</span>
            </li>
            <li className="engine-type-table-text price"><span>$40,814.00</span></li>
          </ul>
          <ul className="engine-type-table-row">
            <div className="engine-option-select"></div>
            <li className="engine-type-table-text model">
            <span>Mercury AXIUS 4.5L DTS | Bravo 3<br></br>380 HP</span>
            </li>
            <li className="engine-type-table-text price"><span>$46,576.00</span></li>
          </ul>
          <ul className="engine-type-table-row">
            <div className="engine-option-select"></div>
            <li className="engine-type-table-text model">
            <span>Mercury Sea Pro XL EFI V8<br></br>250 HP</span>
            </li>
            <li className="engine-type-table-text price"><span>$41,478.00</span></li>
          </ul>
          <ul className="engine-type-table-row">
            <div className="engine-option-select"></div>
            <li className="engine-type-table-text model">
            <span>Mercury Verado XL EFI V8<br></br>300 HP</span>
            </li>
            <li className="engine-type-table-text price"><span>$54,857.00</span></li>
          </ul>
        </div>
        <div className="engine-options-buttons-container">
          <ButtonGoBackTwo />
          <ButtonNextStepThree />
        </div>
      </div>
    </section>
  );
}

export default EngineOptions;
