import "./Specs.css";
import "../Carousel/Carousel41.css";
import "../Carousel/Carousel.css";

function Spec41() {
  return (
    <section className="specs-hero">
      <h3 className="specs-hero-title">Specs</h3>
      <div className="carousel-wrapper">
        <span id="item-1-41"></span>
        <span id="item-2-41"></span>
        <span id="item-3-41"></span>
        <span id="item-4-41"></span>
        <span id="item-5-41"></span>
        <div className="carousel-item item-1-41">
          <a className="arrow-carousel arrow-prev" href="#item-5-41">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-2-41">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-2-41">
          <a className="arrow-carousel arrow-prev" href="#item-1-41">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-3-41">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-3-41">
          <a className="arrow-carousel arrow-prev" href="#item-2-41">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-4-41">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-4-41">
          <a className="arrow-carousel arrow-prev" href="#item-3-41">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-5-41">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-5-41">
          <a className="arrow-carousel arrow-prev" href="#item-4-41">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-1-41">
            {" "}
          </a>
        </div>
      </div>
      <div className="specs-lists-container">
        <div className="specs-list-a">
          <table className="specs-list">
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Length Overall:</td>
              <td className="specs-list-right body-text-lg">42.32 ft | 12.9 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Beam:</td>
              <td className="specs-list-right body-text-lg">11.48 ft | 3.5 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Heavy Draft:</td>
              <td className="specs-list-right body-text-lg">2.95 ft | 0.9 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Light Draft:</td>
              <td className="specs-list-right body-text-lg">2.14 ft | 0.65 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Height:</td>
              <td className="specs-list-right body-text-lg">11.98 ft | 3.65 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Dry Weight:</td>
              <td className="specs-list-right body-text-lg">23,149 lbs | 10,500 kg</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Fuel Types:</td>
              <td className="specs-list-right body-text-lg">Gasoline | Diesel</td>
            </tr>
          </table>
        </div>
        <div className="specs-list-b">
          <table className="specs-list">
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Bathrooms:</td>
              <td className="specs-list-right body-text-lg">1 WC or 2 WC</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Fuel Capacity:</td>
              <td className="specs-list-right body-text-lg">211.34 Gallon | 800 Liter</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Water Capacity:</td>
              <td className="specs-list-right body-text-lg">105.67 Gallon | 400 Liter</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Engine Brands:</td>
              <td className="specs-list-right body-text-lg">
                Volvo Penta | Mercury MerCruiser
              </td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Passenger Capacity:</td>
              <td className="specs-list-right body-text-lg">14 + 1</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Propulsion Types:</td>
              <td className="specs-list-right body-text-lg">
                Outboard | Sterndrive (Center Tail)
              </td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Cabins:</td>
              <td className="specs-list-right body-text-lg">2 Cabins</td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Spec41;
