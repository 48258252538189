import "./ButtonLight.css";
import { Link } from "react-router-dom";
import BtnArrowLight from "../../media/img/btn-arrow-light.svg";

function ButtonContact() {
  return (
    <Link to="/contact">
      <a className="button ButtonContact" href="#contact">
        CONTACT US
        <img className="arrow" src={BtnArrowLight} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonContact;
