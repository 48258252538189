import "./BuildHome.css";
import photo from "../../../media/img/landing-build.png";
import ButtonBuild from "../../Buttons/ButtonBuild";

function BuildHome() {
  return (
    <section className="build-hero">
      <img className="build-image" src={photo} alt="img" />
      <div className="build-card main-2">
        <h3 className="build-card-title">Design your yacht with Tethys</h3>
        <p className="build-card-subtitle body-text-lg">
          Customize your yacht with the options that fit the way you boating.
        </p>
        <ButtonBuild />
      </div>
    </section>
  );
}

export default BuildHome;
