import "./ButtonLight.css";
import { Link } from "react-router-dom";
import BtnArrowLight from "../../media/img/btn-arrow-light.svg";

function ButtonBuild31() {
  return (
    <Link to="/config/31targa/step1">
      {/* <Link to="/coming-soon"> */}
      <a className="button ButtonBuild31" href="#31targa/builder/step/1">
        BUILD
        <img className="arrow" src={BtnArrowLight} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonBuild31;
