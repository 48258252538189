import "./AboutSections.css";
import image from "../../../media/img/abyc.png";

function ABYC() {
  return (
    <section className="about-container">
      <div className="about-abyc-container">
        <img
          className="about-abyc-image"
          src={image}
          alt="img"
        ></img>
        <div className="about-abyc-content-container">
          <h2 className="about-abyc-title">
            We Are <strong>ABYC</strong>
          </h2>
          <p className="about-abyc-paragraph body-text-lg">
            ABYC, American Boat and Yacht Council, is an organization that has
            been developing and updationg safety for boat buildind and repair
            for 50 years.
          </p>
          <p className="about-abyc-paragraph body-text-lg">
            Actively involved with the International Standardization
            Organization (ISO) and a leader in shipbuilding education as well as
            certification programs for maritime technicians. We are proud to
            have ABYC seal on our boats.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ABYC;
