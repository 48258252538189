import tethys_logo from "../../../media/img/logo-tethys__dark.svg";
import React, { useState } from "react";
import facebook from "../../../media/img/facebook.svg";
import instagram from "../../../media/img/instagram.svg";
import LanguageSelector from "../Dropdown/LanguageSelector";
import { Link } from "react-router-dom";
import "./NavBar.css";
import Dropdown from "../Dropdown/Dropdown";

function NavBar() {
  const linkStyle = {
    textDecoration: "none",
    marginRight: "50px",
  };

  const [click, setClick] = useState(false);
  const [yachtsDropdown, setYachtsDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnterYachts = () => {
    if (window.innerWidth >= 960) {
      setYachtsDropdown(true);
    }
  };

  const onMouseEnterLanguage = () => {
    if (window.innerWidth >= 960) {
      setLanguageDropdown(true);
    }
  };

  return (
    <div className="navbar-hero">
      <Link to="/" onClick={closeMobileMenu}>
        <div className="navbar-brand">
          <img className="navbar-tethys-logo" src={tethys_logo} alt="logo" />
          <div className="navbar-vertical-divider"></div>
          <p className="navbar-arb-logo arb-dark">
            Alex&#32;Roman
            <br />
            Boats
          </p>
        </div>
      </Link>
      <div className={click ? "navbar-start" : "navbar-start"}>
        <nav>
          <Link style={linkStyle} to="/about" onClick={closeMobileMenu}>
            <a href="#about" className="navbar-nav-link nav-text ml-1">
              ABOUT
            </a>
          </Link>
          <a
            href="#drpdwn"
            className="navbar-nav-link-dropdown nav-text"
            onMouseEnter={onMouseEnterYachts}>
            <Link onClick={closeMobileMenu}>YACHTS</Link>
            {yachtsDropdown && <Dropdown id="drpdwn" />}{" "}
            <i className="navbar-dropdown-arrow"></i>
          </a>
          <Link style={linkStyle} to="/build" onClick={closeMobileMenu}>
            <a href="#build" className="navbar-nav-link nav-text ml-1">
              BUILD
            </a>
          </Link>
          <Link style={linkStyle} to="/contact" onClick={closeMobileMenu}>
            <a href="#contact" className="navbar-nav-link nav-text">
              CONTACT
            </a>
          </Link>
        </nav>
      </div>
      <div className="navbar-end">
        <nav>
          <a
            href="#langdrpdwn"
            className="navbar-nav-link-dropdown nav-text"
            onMouseEnter={onMouseEnterLanguage}>
            <Link onClick={closeMobileMenu}>ENG</Link>
            {languageDropdown && <LanguageSelector id="drpdwn" />}{" "}
            <i className="navbar-dropdown-arrow"></i>
          </a>
          <div className="social-icon-container">
            <a href="#instagram">
              <img
                src={instagram}
                alt="instagram"
                className="social-icon"></img>
            </a>
            <a href="#facebook">
              <img src={facebook} alt="facebook" className="social-icon"></img>
            </a>
          </div>
        </nav>
        <div className="navbar-burger-lines__line1"></div>
        <div className="navbar-burger-lines__line2"></div>
        <div className="navbar-burger-lines__line3"></div>
      </div>
    </div>
  );
}

export default NavBar;
