import "./ButtonDark.css";
import { Link } from "react-router-dom";

function ButtonSendMeACopy() {
  return (
    <Link to="/config/41ht/overview/export">
      <a className="button ButtonSendMeACopy" href="#54fly/builder/overview/export">
        SEND ME A COPY
      </a>
    </Link>
  );
}

export default ButtonSendMeACopy;
