import "./HeroModel.css";
import hero54 from "../../../media/img/hero54.png";
import ButtonBuild54 from "../../Buttons/ButtonBuild54";
import Slider54 from "../../Slider/Slider54";

function Hero54() {
  return (
    <section className="model-hero bg">
      <div className="model-hero-intro">
        <h1 className="model-hero-title">54’ FLY</h1>
        <img className="model-hero-image" src={hero54} alt="img"></img>
        <h5 className="model-hero-description">
          The flagship as it comes. Spacious modern yacht with all the latest
          hi-tech marine solutions. Every detail of the boat aims to provide the
          best possible feel and usability.
        </h5>
        <ButtonBuild54 />
      </div>
      <Slider54 />
    </section>
  );
}

export default Hero54;
