import "./HeroBuild.css";
import ButtonBuild41 from "../../Buttons/ButtonBuild41";
import ButtonBuild31 from "../../Buttons/ButtonBuild31";
import ButtonBuild54 from "../../Buttons/ButtonBuild54";
import ButtonBuild35 from "../../Buttons/ButtonBuild35";
import model_31 from "../../../media/img/landing-featured-31.png";
import model_35 from "../../../media/img/landing-featured-35.png";
import model_41 from "../../../media/img/landing-featured-41.png";
import model_54 from "../../../media/img/landing-featured-54.png";

function HeroBuild() {
  return (
    <section className="yacht-selector-hero bg">
      <h1 className="yacht-selector-hero-title black">Choose The Yacht</h1>
      <div className="yacht-selector-container">
        <div className="yacht-selector">
          <img
            className="yacht-selector-image-1"
            src={model_31}
            alt="img"
          ></img>
          <h5 className="yacht-selector-model-title black">31' Targa</h5>
          <div className="yacht-selector-button">
            <ButtonBuild31 />
          </div>
        </div>
        <div className="yacht-selector">
          <img
            className="yacht-selector-image-2"
            src={model_35}
            alt="img"
          ></img>
          <h5 className="yacht-selector-model-title black">35' HT</h5>
          <div className="yacht-selector-button">
            <ButtonBuild35 />
          </div>
        </div>
        <div className="yacht-selector">
          <img
            className="yacht-selector-image-3"
            src={model_41}
            alt="img"
          ></img>
          <h5 className="yacht-selector-model-title black">41' HT</h5>
          <div className="yacht-selector-button">
            <ButtonBuild41 />
          </div>
        </div>
        <div className="yacht-selector">
          <img
            className="yacht-selector-image-4"
            src={model_54}
            alt="img"
          ></img>
          <h5 className="yacht-selector-model-title black">54' Fly</h5>
          <div className="yacht-selector-button">
            <ButtonBuild54 />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroBuild;
