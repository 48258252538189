import "./NavBarBurger.css";
import tethys_logo from "../../../media/img/logo-tethys__light.svg";
import close from "../../../media/img/close.svg";

// import { Link } from "react-router-dom";

function NavBarBurger() {
  return (
    <section className="navbar-burger-container">
      <div className="navbar-burger-brand">
        <div className="navbar-burger-header">
          <img
            className="navbar-burger-tethys-logo"
            src={tethys_logo}
            alt="logo"
          />
          <div className="navbar-burger-logo-vertical-divider"></div>
          <p className="navbar-burger-arb-logo arb-light">
            Alex&#32;Roman
            <br />
            Boats
          </p>
        </div>
        <div>
          <img src={close} alt="close" />
        </div>
      </div>
      <div className="navbar-burger-body">
        <h3 className="nav-link-burger-lg white">About</h3>
        <h3 className="nav-link-burger-lg white underline">Yachts</h3>
        <div className="navbar-burger-models">
          <div className="navbar-burger-model-container-top">
            <h4 className="nav-link-burger-sm white underline">31' Targa</h4>
            <h4 className="nav-link-burger-sm white">35' HT</h4>
          </div>
          <div className="navbar-burger-model-container-bottom">
            <h4 className="nav-link-burger-sm white">41' HT</h4>
            <h4 className="nav-link-burger-sm white">54' Fly</h4>
          </div>
        </div>
        <h3 className="nav-link-burger-lg white">Build</h3>
        <h3 className="nav-link-burger-lg white">Contact</h3>
      </div>
      <div className="navbar-burger-footer">
        <span className="navbar-burger-lang body-text-md-light">Languages:</span>
        <span className="nav-link-footer body-text-md-light underline">ENG</span>
        <span className="nav-link-footer body-text-md-light">ES</span>
        <span className="nav-link-footer body-text-md-light">RU</span>
      </div>
    </section>
  );
}

export default NavBarBurger;
