import Nav from "../components/Nav/NavBar/NavBar";
import Hero from "../components/Heros/Models/Hero31";
import Spec from "../components/Specs/Spec31";
import Video from "../components/Video/Video31";
import Gallery from "../components/Galleries/Gallery31";
import Build from "../components/Build/BuildModel/BuildModel";
import Featured from "../components/Featured/Featured31";
import Dealers from "../components/Dealers/Dealers";
import Footer from "../components/Footer/Footer";

function Targa31() {
  return (
    <body>
      <Nav />
      <Hero />
      <Spec />
      <Video />
      <Gallery />
      <Build />
      <Featured />
      <Dealers />
      <Footer />
    </body>
  );
}

export default Targa31;
