import "./HeroModel.css";
import hero41 from "../../../media/img/hero41.png";
import ButtonBuild41 from "../../Buttons/ButtonBuild41";
import Slider41 from "../../Slider/Slider41";

function Hero41() {
  return (
    <section className="model-hero bg">
      <div className="model-hero-intro">
        <h1 className="model-hero-title">41’ HT</h1>
        <img className="model-hero-image" src={hero41} alt="img"></img>
        <h5 className="model-hero-description">
          The bestselling model. Versatility at its finest. Explore intracoastal
          waters or enjoy the trip or the Caribbean. This yacht is your
          all-weather water residence.
        </h5>
        <ButtonBuild41 />
      </div>
      <Slider41 />
    </section>
  );
}

export default Hero41;
