import NavBar from "../components/Nav/NavBar/NavBar";
import Hero from "../components/Config/Overview/Section";
import Form from "../components/Form/Form";
import Footer from "../components/Footer/Footer";

function Overview() {
  return (
    <body>
      <NavBar />
      <Hero />
      <Form />
      <Footer />
    </body>
  );
}

export default Overview;
