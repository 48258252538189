import "./Galleries.css";
import gallery_1 from "../../media/img/interior-31-1.png";
import gallery_2 from "../../media/img/interior-31-2.jpg";
import gallery_3 from "../../media/img/interior-31-3.png";
import gallery_4 from "../../media/img/interior-31-4.jpeg";
import gallery_5 from "../../media/img/interior-31-5.jpg";
import gallery_6 from "../../media/img/interior-31-6.jpeg";
import gallery_7 from "../../media/img/interior-31-7.png";

function Gallery31() {
  return (
    <section>
      <h3 className="model-gallery-hero-title black">Media</h3>
      <div className="model-gallery-container">
        <div className="model-gallery-side">
          <img
            className="model-gallery-image-side"
            src={gallery_1}
            alt="img"
          ></img>
          <img
            className="model-gallery-image-side"
            src={gallery_2}
            alt="img"
          ></img>
          <img
            className="model-gallery-image-side"
            src={gallery_3}
            alt="img"
          ></img>
        </div>
        <div className="model-gallery-center">
          <img
            className="model-gallery-image-center"
            src={gallery_4}
            alt="img"
          ></img>
        </div>
        <div className="model-gallery-side">
          <img
            className="model-gallery-image-side"
            src={gallery_5}
            alt="img"
          ></img>
          <img
            className="model-gallery-image-side"
            src={gallery_6}
            alt="img"
          ></img>
          <img
            className="model-gallery-image-side"
            src={gallery_7}
            alt="img"
          ></img>
        </div>
      </div>
    </section>
  );
}

export default Gallery31;
