import "./Specs.css";
import "../Carousel/Carousel54.css";
import "../Carousel/Carousel.css";

function Spec54() {
  return (
    <section className="specs-hero">
      <h3 className="specs-hero-title">Specs</h3>
      <div className="carousel-wrapper">
        <span id="item-1-54"></span>
        <span id="item-2-54"></span>
        <span id="item-3-54"></span>
        <span id="item-4-54"></span>
        <span id="item-5-54"></span>
        <div className="carousel-item item-1-54">
          <a className="arrow-carousel arrow-prev" href="#item-5-54">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-2-54">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-2-54">
          <a className="arrow-carousel arrow-prev" href="#item-1-54">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-3-54">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-3-54">
          <a className="arrow-carousel arrow-prev" href="#item-2-54">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-4-54">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-4-54">
          <a className="arrow-carousel arrow-prev" href="#item-3-54">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-5-54">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-5-54">
          <a className="arrow-carousel arrow-prev" href="#item-4-54">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-1-54">
            {" "}
          </a>
        </div>
      </div>
      <div className="specs-lists-container">
        <div className="specs-list-a">
          <table className="specs-list">
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Length Overall:</td>
              <td className="specs-list-right body-text-lg">55.77 ft | 17 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Beam:</td>
              <td className="specs-list-right body-text-lg">15.75 ft | 4.8 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Heavy Draft:</td>
              <td className="specs-list-right body-text-lg">3.94 ft | 1.2 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Light Draft:</td>
              <td className="specs-list-right body-text-lg">2.62 ft | 0.8 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Height:</td>
              <td className="specs-list-right body-text-lg">15.09 ft | 4.6 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Dry Weight:</td>
              <td className="specs-list-right body-text-lg">39,648 lbs | 18,000 kg</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Fuel Types:</td>
              <td className="specs-list-right body-text-lg">Diesel</td>
            </tr>
          </table>
        </div>
        <div className="specs-list-b">
          <table className="specs-list">
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Bathrooms:</td>
              <td className="specs-list-right body-text-lg">2 WC + Captain's WC</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Fuel Capacity:</td>
              <td className="specs-list-right body-text-lg">438.53 Gallon | 1,660 Liter</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Water Capacity:</td>
              <td className="specs-list-right body-text-lg">171.71 Gallon | 650 Liter</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Engine Brands:</td>
              <td className="specs-list-right body-text-lg">Volvo Penta</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Passenger Capacity:</td>
              <td className="specs-list-right body-text-lg">16 + 1 (Day) / 6 + 1 (Night)</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Propulsion Types:</td>
              <td className="specs-list-right body-text-lg">IPS</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Cabins:</td>
              <td className="specs-list-right body-text-lg">
                3 Cabins + Captain's Quarters
              </td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Spec54;
