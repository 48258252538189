import Nav from "../components/Nav/NavBar/NavBar";
import Steps from "../components/Config/Steps/Step2";
import Main from "../components/Config/Engine/Engine";
import Preview from "../components/Config/Overview/Preview";

function Step2() {
  return (
    <body>
      <Nav />
      <Steps />
      <Main />
      <Preview />
    </body>
  );
}

export default Step2;
