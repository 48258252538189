import "./ButtonLight.css";
import { Link } from "react-router-dom";
import BtnArrowLight from "../../media/img/btn-arrow-light.svg";

function ButtonAbout() {
  return (
    <Link to="/about">
      <a className="button ButtonAbout" href="#about">
        ABOUT US
        <img className="arrow" src={BtnArrowLight} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonAbout;
