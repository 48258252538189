import "./Footer.css";
import facebook from "../../media/img/facebook-light.svg";
import instagram from "../../media/img/instagram-light.svg";

function FooterCopyright() {
  return (
    <div className="footer-copyright main-3">
      <div className="footer-social-icon-container">
        <a href="#instagram">
          <img
            src={instagram}
            alt="instagram"
            className="footer-social-icon"
          ></img>
        </a>
        <a href="#facebook">
          <img
            src={facebook}
            alt="facebook"
            className="footer-social-icon"
          ></img>
        </a>
      </div>
      <p className="footer-copyright-text">
        Alex Roman Boats &copy; 2021 Copyright
      </p>
    </div>
  );
}

export default FooterCopyright;
