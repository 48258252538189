import "./Footer.css";
import tethys_logo from "../../media/img/logo-tethys__light.svg";
import { Link } from "react-router-dom";
import ButtonFooter from "../Buttons/ButtonFooter";
import Tooltip from "./Tooltip";
import Copyright from "./Copyright";
import React from "react";

function Footer() {
  return (
    <section className="main-3">
      <div className="footer-top">
        <Link to="/">
          <div className="footer-brand">
            <img className="footer-tethys-logo" src={tethys_logo} alt="logo" />
            <div className="footer-logo-vertical-divider"></div>
            <p className="footer-arb-logo arb-light">
              Alex&#32;Roman
              <br />
              Boats
            </p>
          </div>
        </Link>
        <div className="footer-categories">
          <div className="footer-category-models">
            <p className="footer-category-title white">MODELS</p>
            <ul className="footer-category-list">
              <li className="footer-category-list__item">
                <Link to="/31targa">
                  <span className="footer-span-item body-text-sm-light">
                    31' TARGA
                  </span>
                </Link>
              </li>
              <li className="footer-category-list__item">
                <Link to="/35ht">
                  <span className="footer-span-item body-text-sm-light">
                    35' HT
                  </span>
                </Link>
              </li>
              <li className="footer-category-list__item">
                <Link to="/41ht">
                  <span className="footer-span-item body-text-sm-light">
                    41' HT
                  </span>
                </Link>
              </li>
              <li className="footer-category-list__item">
                <Link to="/54fly">
                  <span className="footer-span-item body-text-sm-light">
                    54' FLY
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-category-contact">
            <p className="footer-category-title white">CONTACT</p>
            <ul className="footer-category-list">
              <li className="footer-category-list__item body-text-sm-light">
                Email:
                <br />
                <button
                  className="footer-span-item main-3 tooltip body-text-sm-light"
                  onClick={() =>
                    navigator.clipboard.writeText("info@alexroman.boats")
                  }
                >
                  <Tooltip text="Click to copy!">info@alexroman.boats</Tooltip>
                </button>
              </li>
              <li className="footer-category-list__item body-text-sm-light">
                Phone:
                <br />
                <button
                  className="footer-span-item main-3 tooltip body-text-sm-light"
                  onClick={() =>
                    navigator.clipboard.writeText("+1 (305) 771-4059")
                  }
                >
                  <Tooltip text="Click to copy!">+1 (305) 771-4059</Tooltip>
                </button>
              </li>
              <li className="footer-category-list__item body-text-sm-light">
                Address:
                <br />
                <button
                  className="footer-span-item main-3 tooltip body-text-sm-light"
                  onClick={() =>
                    navigator.clipboard.writeText("Miami Shores, FL, 33138")
                  }
                >
                  <Tooltip text="Click to copy!">
                    Miami Shores, FL, 33138
                  </Tooltip>
                </button>
              </li>
            </ul>
          </div>
          <div className="footer-category-build">
            <p className="footer-category-title white">BUILD</p>
            <ul className="footer-category-list">
              <li className="footer-category-list__item footer-desc body-text-sm-light">
                Customize your yacht with the options that fit the way you
                boating.
              </li>
              <li className="footer-category-list__item">
                <ButtonFooter />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Copyright />
    </section>
  );
}

export default Footer;
