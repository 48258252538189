import "./AboutSections.css";
import image_1 from "../../../media/img/about-photo-2.png";
import image_2 from "../../../media/img/about-photo-3.png";

function Info() {
  return (
    <section className="about-container">
      <div className="about-info-container">
        <img className="about-info-image-1" src={image_1} alt="img"></img>
        <div className="about-info-content-container">
          <p className="about-info-paragraph body-text-lg">
            Tethys shipyard in Venacio Aires, Rio Grande so Sul, has an area of
            13.500 m2 to build vessels and provide incredible on water
            experiences since 2016. We stand for the expectional quality built
            ships in the nautical market.
          </p>
          <img className="about-info-image-2" src={image_2} alt="img"></img>
          <p className="about-info-paragraph body-text-lg">
            In addition to beautiful and sophitiscated our vessels offer top
            notch technology. All this associated with great performance,
            service and best resale value, make Tethys Yachts unique.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Info;
