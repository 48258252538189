import "./Featured.css";
import ButtonFeatured31 from "../Buttons/ButtonFeatured31";
import ButtonFeatured54 from "../Buttons/ButtonFeatured54";
import ButtonFeatured35 from "../Buttons/ButtonFeatured35";
import model_31 from "../../media/img/landing-featured-31.png";
import model_35 from "../../media/img/landing-featured-35.png";
import model_54 from "../../media/img/landing-featured-54.png";

function MoreFrom41() {
  return (
    <section className="featured-hero bg">
      <h3 className="featured-hero-title">More From Tethys</h3>
      <div className="featured-yachts-container">
        <div className="featured-yacht">
          <img
            className="featured-yacht-image-1"
            src={model_31}
            alt="img"
          ></img>
          <h4 className="featured-yacht-title">31' Targa</h4>
          <p className="featured-yacht-description body-text-lg">
            Yachting experience. Hassle free. We engineered the 31' Targa to be
            the most practical yacht ever...
          </p>
          <div className="featured-yacht-button">
            <ButtonFeatured31 />
          </div>
        </div>
        <div className="featured-yacht">
          <img
            className="featured-yacht-image-2"
            src={model_35}
            alt="img"
          ></img>
          <h4 className="featured-yacht-title">35' HT</h4>
          <p className="featured-yacht-description body-text-lg">
            Yachting experience. Hassle free. We engineered the 35' HT to be the
            most practical yacht ever...
          </p>
          <div className="featured-yacht-button">
            <ButtonFeatured35 />
          </div>
        </div>
        <div className="featured-yacht">
          <img
            className="featured-yacht-image-4"
            src={model_54}
            alt="img"
          ></img>
          <h4 className="featured-yacht-title">54' Fly</h4>
          <p className="featured-yacht-description body-text-lg">
            The flagship as it comes. Spacious modern yacht with all the latest
            hi-tech marine solutions...
          </p>
          <div className="featured-yacht-button">
            <ButtonFeatured54 />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MoreFrom41;
