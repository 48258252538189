import Nav from "../components/Nav/NavBar/NavBar";
import Hero from "../components/Heros/Models/Hero41";
import Spec from "../components/Specs/Spec41";
import Video from "../components/Video/Video41";
import Gallery from "../components/Galleries/Gallery41";
import Build from "../components/Build/BuildModel/BuildModel";
import Featured from "../components/Featured/Featured41";
import Dealers from "../components/Dealers/Dealers";
import Footer from "../components/Footer/Footer";

function HT41() {
  return (
    <body>
      <Nav />
      <Hero />
      <Spec />
      <Video />
      <Gallery />
      <Build />
      <Featured />
      <Dealers />
      <Footer />
    </body>
  );
}

export default HT41;
