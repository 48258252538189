import "./BuildModel.css";
import photo_1 from "../../../media/img/buildyachtmodels-1.png";
import photo_2 from "../../../media/img/buildyachtmodels-2.png";
import ButtonBuildYachtModels from "../../Buttons/ButtonBuildYachtModels";

function BuildModel() {
  return (
    <section className="buildyachtmodels-hero">
      <img className="buildyachtmodels-image-1" src={photo_1} alt="img" />
      <div className="buildyachtmodels-card main-2">
        <h3 className="buildyachtmodels-card-title">
          Design your yacht with Tethys
        </h3>
        <p className="buildyachtmodels-card-subtitle body-text-lg">
          Customize your yacht with the options that fit the way you boating.
        </p>
        <ButtonBuildYachtModels />
      </div>
      <img className="buildyachtmodels-image-2" src={photo_2} alt="img" />
    </section>
  );
}

export default BuildModel;
