import ButtonContact from "../Buttons/ButtonContact";
import ButtonHome from "../Buttons/ButtonHome";
import "./ComingSoon.css";
import logo from "../../media/img/coming-soon.webp";

function ComingSoon() {
  return (
    <section>
      <div className="coming-soon-container">
        <div className="coming-soon-img-contianer">
          <img className="coming-soon-img" src={logo} alt="img"></img>
        </div>
        <h2 className="coming-soon-title">
          This feature will be released soon!
        </h2>
        <h4 className="coming-soon-subtitle">
          Check back shortly or contact us.
        </h4>
        <div className="coming-soon-button-container">
          <ButtonHome />
          <ButtonContact />
        </div>
      </div>
    </section>
  );
}

export default ComingSoon;
