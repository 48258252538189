import "./Paint.css";
import ButtonGoBackOne from "../../Buttons/ButtonGoBackOne";
import ButtonNextStepTwo from "../../Buttons/ButtonNextStepOne";

function Paint() {
  return (
    <section className="external-paint-container">
      <h2 className="external-paint-title-adaptive black">
        STEP 1:<br></br>External Paint
      </h2>
      <div className="external-paint-colors">
        <div className="external-paint-color">
          <div className="external-paint-color-img"></div>
          <p className="external-paint-title black">WHITE</p>
          <p className="external-paint-price black">$1,047.00</p>
        </div>
        <div className="external-paint-color">
          <div className="external-paint-color-img config-grey"></div>
          <p className="external-paint-title black">GREY</p>
          <p className="external-paint-price black">$1,047.00</p>
        </div>
        <div className="external-paint-color">
          <div className="external-paint-color-img config-blue"></div>
          <p className="external-paint-title black">BLUE</p>
          <p className="external-paint-price black">$1,047.00</p>
        </div>
        <div className="external-paint-color">
          <div className="external-paint-color-img config-gun-metal-grey"></div>
          <p className="external-paint-title black">GUN METAL GREY</p>
          <p className="external-paint-price black">$9,351.00</p>
        </div>
      </div>
      <div className="external-paint-buttons-container">
        <ButtonGoBackOne />
        <ButtonNextStepTwo />
      </div>
    </section>
  );
}

export default Paint;
