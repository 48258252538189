import "./ButtonLight.css";
import { Link } from "react-router-dom";
import BtnArrowLight from "../../media/img/btn-arrow-light.svg";

function ButtonSubmit() {
  return (
    <Link to="/">
      <a className="button ButtonSubmit" href="#form-submitted">
        SUBMIT
        <img className="arrow" src={BtnArrowLight} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonSubmit;
