import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image_1 from "../../media/img/contact-image-1.jpg";
import image_2 from "../../media/img/interior-41-4.png";
import image_3 from "../../media/img/hero54.png";
import image_4 from "../../media/img/contact-image-4.jpeg";
import image_5 from "../../media/img/contact-image-5.jpg";
import image_6 from "../../media/img/landing-featured-31.png";
import "./Slider.css";

export default class SliderContact extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
          },
        },
        {
          breakpoint: 768,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
          },
        },
        {
          breakpoint: 428,
          settings: {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings} className="overflow-remove">
          <div>
            <img className="slider-image" src={image_1} alt="img"></img>
          </div>
          <div>
            <img className="slider-image" src={image_2} alt="img"></img>
          </div>
          <div>
            <img className="slider-image" src={image_3} alt="img"></img>
          </div>
          <div>
            <img className="slider-image" src={image_4} alt="img"></img>
          </div>
          <div>
            <img className="slider-image" src={image_5} alt="img"></img>
          </div>
          <div>
            <img className="slider-image" src={image_6} alt="img"></img>
          </div>
        </Slider>
      </div>
    );
  }
}
