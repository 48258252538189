export const Languages = [
    {
      title: "ENG",
      path: "/",
      cName: "dropdown-link",
    },
    {
      title: "RU",
      path: "/",
      cName: "dropdown-link",
    },
    {
      title: "ES",
      path: "/",
      cName: "dropdown-link",
    }
  ];
  