import "../NavBar/NavBar.css";
import React, { useState } from "react";
import tethys_logo from "../../../media/img/logo-tethys__light.svg";
import { Link } from "react-router-dom";
import Dropdown from "../Dropdown/Dropdown";
import LanguageSelector from "../Dropdown/LanguageSelector";
import facebook from "../../../media/img/facebook-light.svg";
import instagram from "../../../media/img/instagram-light.svg";

function Header() {
  const [click, setClick] = useState(false);
  const [yachtsDropdown, setYachtsDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnterYachts = () => {
    if (window.innerWidth >= 960) {
      setYachtsDropdown(true);
    }
  };

  const onMouseEnterLanguage = () => {
    if (window.innerWidth >= 960) {
      setLanguageDropdown(true);
    }
  };

  return (
    <div className="navbar-hero">
      <Link to="/">
        <div className="navbar-brand">
          <img className="navbar-tethys-logo" src={tethys_logo} alt="logo" />
          <div className="header-vertical-divider"></div>
          <p className="header-arb-logo arb-light">
            Alex&#32;Roman
            <br />
            Boats
          </p>
        </div>
      </Link>
      <div className={click ? "navbar-start" : "navbar-start"}>
        <nav>
          <Link to="/about" onClick={closeMobileMenu}>
            <span className="header-nav-link nav-text">ABOUT</span>
          </Link>
          <a
            href="#drpdwn"
            className="header-nav-link-dropdown nav-text"
            onMouseEnter={onMouseEnterYachts}>
            <Link onClick={closeMobileMenu}>YACHTS</Link>
            {yachtsDropdown && <Dropdown id="drpdwn" />}{" "}
            <i className="header-dropdown-arrow"></i>
          </a>
          <Link to="/build" onClick={closeMobileMenu}>
            <span className="header-nav-link nav-text ml-1">BUILD</span>
          </Link>
          <Link to="/contact" onClick={closeMobileMenu}>
            <span className="header-nav-link nav-text">CONTACT</span>
          </Link>
        </nav>
      </div>
      <div className="navbar-end">
        <nav className="navbar-end-container">
          <a
            href="#drpdwn"
            className="header-nav-link-dropdown nav-text"
            onMouseEnter={onMouseEnterLanguage}>
            <Link onClick={closeMobileMenu}>ENG</Link>
            {languageDropdown && <LanguageSelector id="drpdwn" />}{" "}
            <i className="header-dropdown-arrow"></i>
          </a>
          <div className="social-icon-container">
            <a href="#instagram">
              <img
                src={instagram}
                alt="instagram"
                className="social-icon"></img>
            </a>
            <a href="#facebook">
              <img src={facebook} alt="facebook" className="social-icon"></img>
            </a>
          </div>
        </nav>
        <div className="header-burger-lines__line1"></div>
        <div className="header-burger-lines__line2"></div>
        <div className="header-burger-lines__line3"></div>
      </div>
    </div>
  );
}

export default Header;
