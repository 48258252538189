import Nav from "../components/Nav/NavBar/NavBar";
import Hero from "../components/Heros/Models/Hero35";
import Spec from "../components/Specs/Spec35";
import Gallery from "../components/Galleries/Gallery35";
import Build from "../components/Build/BuildModel/BuildModel";
import Featured from "../components/Featured/Featured35";
import Dealers from "../components/Dealers/Dealers";
import Footer from "../components/Footer/Footer";

function HT35() {
  return (
    <body>
      <Nav />
      <Hero />
      <Spec />
      <Gallery />
      <Build />
      <Featured />
      <Dealers />
      <Footer />
    </body>
  );
}

export default HT35;
