import "./ButtonDark.css";
import { Link } from "react-router-dom";

function ButtonNextStepOne() {
  return (
    <Link to="/config/41ht/step2">
      <a className="button ButtonNextStepOne" href="#config/41ht/step2">
        NEXT STEP
      </a>
    </Link>
  );
}

export default ButtonNextStepOne;
