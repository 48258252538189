import "./AboutSections.css";
import image from "../../../media/img/about-photo-5.png";
import logo from "../../../media/img/CE.webp";

function CE() {
  return (
    <section className="about-container">
      <div className="about-ce-container">
        <img className="about-ce-image" src={image} alt="img"></img>
        <div className="about-ce-content-container">
          <h2 className="about-ce-title">
            We Are
            <img className="about-ce-logo" src={logo} alt="img"></img>
          </h2>
          <p className="about-ce-paragraph body-text-lg">
            Our vessels are produced in accordance with European certification.
            The CE letters on the products are abbreviated from the initials of
            the French Conformité Européene meaning European Conformity.
          </p>
          <p className="about-ce-paragraph body-text-lg">
            This certificate proves that the products are evaluated under the
            relevant directives and comply with the New Approach Directives in
            terms of safety, health and environmental protection requirements.
          </p>
        </div>
      </div>
    </section>
  );
}

export default CE;
