import "./ButtonLight.css";
import { Link } from "react-router-dom";

function ButtonGoBackThree() {
  return (
    <Link to="/config/41ht/step2">
      <a className="button ButtonGoBackThree" href="#config/41ht/step2">
        GO BACK
      </a>
    </Link>
  );
}

export default ButtonGoBackThree;
