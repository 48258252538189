import Nav from "../components/Nav/NavBar/NavBar";
import Hero from "../components/Heros/Contact/HeroContact";
import Form from "../components/Form/Form";
import Dealers from "../components/Dealers/Dealers";
import Footer from "../components/Footer/Footer";

function Contact() {
  return (
    <body>
      <Nav />
      <Hero />
      <Form />
      <Dealers />
      <Footer />
    </body>
  );
}

export default Contact;
