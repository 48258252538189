import "./HeroModel.css";
import hero31 from "../../../media/img/hero31.png";
import ButtonBuild31 from "../../Buttons/ButtonBuild31";
import Slider31 from "../../Slider/Slider31";

function Hero31() {
  return (
    <section className="model-hero bg" id="hero31">
      <div className="model-hero-intro">
        <h1 className="model-hero-title">31’ HT</h1>
        <img className="model-hero-image" src={hero31} alt="img"></img>
        <h5 className="model-hero-description">
          Yachting experience. Hassle free. We engineered the 31HT to be the
          most practical yacht ever. Everything you expect from a top-notch
          cruiser packed into a compact 31ft hull.
        </h5>
        <ButtonBuild31 />
      </div>
      <Slider31 />
    </section>
  );
}

export default Hero31;
