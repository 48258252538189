import "./ButtonLight.css";
import { Link } from "react-router-dom";
import BtnArrowLight from "../../media/img/btn-arrow-light.svg";

function ButtonFeatured41() {
  return (
    <Link to="/41ht">
      <a className="button ButtonFeatured" href="#41ht">
        INFO
        <img className="arrow" src={BtnArrowLight} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonFeatured41;
