import "../../../helpers/popups.css";
import Popup from "reactjs-popup";

function Brazil() {
  return (
    <Popup trigger={<button className="button"><h1>Brazil</h1></button>} modal nested>
      {(close) => (
        <div className="modal">
          <button className="close" onClick={close}>
            &times;
          </button>
          <div className="header-country"><h1>Brazil</h1></div>
          <div className="content">
            {" "}
            <a href="#tethys-yachts">
              <div className="dealer-container">
                <h5 className="dealer-title">Tethys Yachts</h5>
                <p className="dealer-location body-text-lg">VENACIO AIRES, RS, BRAZIL</p>
              </div>
            </a>
            <a href="#avantgarde-motors">
              <div className="dealer-container">
                <h5 className="dealer-title">Avantgarde Motors</h5>
                <p className="dealer-location body-text-lg">BELO HORIZONTE, MG, BRAZIL</p>
              </div>
            </a>
            <a href="#colin-brokers">
              <div className="dealer-container">
                <h5 className="dealer-title">Colin Brokers</h5>
                <p className="dealer-location body-text-lg">SANTA CATARINA, SC, BRAZIL</p>
              </div>
            </a>
            <a href="#premier-jet">
              <div className="dealer-container">
                <h5 className="dealer-title">Premier Jet</h5>
                <p className="dealer-location body-text-lg">BRAZILIA, DF, BRAZIL</p>
              </div>
            </a>
            <a href="#boatcoats-verde">
              <div className="dealer-container">
                <h5 className="dealer-title">Boatcoats Verde</h5>
                <p className="dealer-location body-text-lg">COROA GRANDE, RJ, BRAZIL</p>
              </div>
            </a>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default Brazil;
