import "./HeroHome.css";
import Header from "../../Nav/Header/Header";
// import Burger from "../../Nav/Burger/NavBarBurger";

function HeroHome() {
  return (
    <section className="landing-hero white">
      <Header />
      {/* <Burger /> */}
      <h1 className="landing-header white">Let's build your own yacht</h1>
    </section>
  );
}

export default HeroHome;
