import "../../../helpers/popups.css";
import Popup from "reactjs-popup";

function USA() {
  return (
    <Popup trigger={<button className="button"><h1>USA</h1></button>} modal nested>
      {(close) => (
        <div className="modal">
          <button className="close" onClick={close}>
            &times;
          </button>
          <div className="header-country"><h1>USA</h1></div>
          <div className="content">
            {" "}
            <a href="#home">
              <div className="dealer-container">
                <h5 className="dealer-title">Alex Roman Boats</h5>
                <p className="dealer-location body-text-lg">MIAMI BEACH, FL, USA</p>
              </div>
            </a>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default USA;
