import "./Section.css";
import ButtonSendMeACopy from "../../Buttons/ButtonSendMeACopy";
import ButtonGoBackOverview from "../../Buttons/ButtonGoBackOverview";

function Overview() {
  return (
    <section className="overview-hero">
      <h1 className="overview-title">Overview</h1>
      <div className="overview-container">
        <div className="overview-container-left"></div>
        <div className="overview-container-right main-3">
          <h3 className="overview-model">41' HT</h3>
          <div className="overview-options-container">
            <div className="overview-section-options-table">
              <div className="overview-section-option-row">
                <span className="overview-section-option-title">
                  BASE BOAT: 41' HT
                </span>
                <span className="overview-section-option-price">
                  $94,205.88
                </span>
              </div>
              <div className="overview-section-option-row">
                <span className="overview-section-option-title">
                  {" "}
                  COLOR: GUN METAL GREY
                </span>
                <span className="overview-section-option-price">$9,351.00</span>
              </div>
            </div>
            <div className="overview-section-total-price"><span>USD $130,302.00</span></div>
          </div>
          <div className="overview-buttons-container">
            <ButtonGoBackOverview />
            <ButtonSendMeACopy />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Overview;
