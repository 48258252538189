import "./ButtonLight.css";
import { Link } from "react-router-dom";
import BtnArrowLight from "../../media/img/btn-arrow-light.svg";

function ButtonBuild41() {
  return (
    <Link to="/coming-soon">
      {/* <Link to="/config/41ht/step1"> */}
      <a className="button ButtonBuild41" href="#41ht/builder/step/1">
        BUILD
        <img className="arrow" src={BtnArrowLight} alt="img" />
      </a>
    </Link>
  );
}

export default ButtonBuild41;
