import "./Preview.css";

function OverviewPreview() {
  return (
    <section>
      <div className="overview-preview-container">
        <div className="overview-preview-top"></div>
        <div className="overview-preview-bottom main-3">
          <div className="overview-preview-options-table">
            <div className="overview-preview-option-row">
              <span className="overview-preview-option-title">
                BASE BOAT: 41' HT
              </span>
              <span className="overview-preview-option-price">$94,205.88</span>
            </div>
          </div>
          <div className="overview-preview-total-price"><span>USD $94,205.88</span></div>
        </div>
      </div>
    </section>
  );
}

export default OverviewPreview;
