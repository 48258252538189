import "./Specs.css";
import "../Carousel/Carousel35.css";
import "../Carousel/Carousel.css";

function Spec35() {
  return (
    <section className="specs-hero">
      <h3 className="specs-hero-title">Specs</h3>
      <div className="carousel-wrapper">
        <span id="item-1-35"></span>
        <span id="item-2-35"></span>
        <span id="item-3-35"></span>
        <div className="carousel-item item-1-35">
          <a className="arrow-carousel arrow-prev" href="#item-3-35">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-2-35">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-2-35">
          <a className="arrow-carousel arrow-prev" href="#item-1-35">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-3-35">
            {" "}
          </a>
        </div>
        <div className="carousel-item item-3-35">
          <a className="arrow-carousel arrow-prev" href="#item-2-35">
            {" "}
          </a>
          <a className="arrow-carousel arrow-next" href="#item-1-35">
            {" "}
          </a>
        </div>
      </div>
      <div className="specs-lists-container">
        <div className="specs-list-a">
          <table className="specs-list">
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Length Overall:</td>
              <td className="specs-list-right body-text-lg">11.05 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Beam:</td>
              <td className="specs-list-right body-text-lg">3.35 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Heavy Draft:</td>
              <td className="specs-list-right body-text-lg">0.80 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Dry Weight:</td>
              <td className="specs-list-right body-text-lg">7,000 kg</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Fuel Types:</td>
              <td className="specs-list-right body-text-lg">Gasoline | Diesel</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Cockpit Ceiling Height:</td>
              <td className="specs-list-right body-text-lg">1.98 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Cabin Ceiling Height:</td>
              <td className="specs-list-right body-text-lg">1.97 m</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Angle V:</td>
              <td className="specs-list-right body-text-lg">20 &deg;</td>
            </tr>
          </table>
        </div>
        <div className="specs-list-b">
          <table className="specs-list">
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Bathrooms:</td>
              <td className="specs-list-right body-text-lg">1 WC</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Fuel Capacity:</td>
              <td className="specs-list-right body-text-lg">700 Liter</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Water Capacity:</td>
              <td className="specs-list-right body-text-lg">280 Liter</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Engine Brands:</td>
              <td className="specs-list-right body-text-lg">Volvo Penta</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Passenger Capacity:</td>
              <td className="specs-list-right body-text-lg">13 + 1</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Cabins:</td>
              <td className="specs-list-right body-text-lg">2 Cabins</td>
            </tr>
            <tr className="specs-list-row">
              <td className="specs-list-left body-text-lg">Toilet Ceiling Height:</td>
              <td className="specs-list-right body-text-lg">1.92 m</td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Spec35;
