import Hero from "../components/Heros/Home/HeroHome";
import Featured from "../components/Featured/Featured";
import About from "../components/About/AboutHome/AboutHome";
import Build from "../components/Build/BuildHome/BuildHome";
import News from "../components/News/News";
import Questions from "../components/Questions/Questions";
import Dealers from "../components/Dealers/Dealers";
import Footer from "../components/Footer/Footer";

function Home() {
  return (
    <body className="Home">
      <Hero />
      <Featured />
      <About />
      <Build />
      <News />
      <Questions />
      <Dealers />
      <Footer />
    </body>
  );
}

export default Home;
