import "./Steps.css";
import { Link } from "react-router-dom";


function BuilderStageOne() {
  return (
    <section>
      <ul className="builderstage-categories">
        <Link to="/config/41ht/step1">
          <li className="builderstage-category-active"><h5>External Paint</h5></li>
        </Link>
        <Link to="/config/41ht/step2">
          <li className="builderstage-category-inactive"><h5>Engine Options</h5></li>
        </Link>
        <Link to="/config/41ht/step3">
          <li className="builderstage-category-inactive"><h5>Equipment Options</h5></li>
        </Link>
        <Link to="/config/41ht/overview">
          <li className="builderstage-category-inactive"><h5>Overview</h5></li>
        </Link>
      </ul>
    </section>
  );
}

export default BuilderStageOne;
