import "./ButtonLight.css";
import { Link } from "react-router-dom";

function ButtonShowMore() {
  return (
    <Link to="/show-more">
      <a className="button ButtonShowMore" href="#show-more">
        SHOW MORE
      </a>
    </Link>
  );
}

export default ButtonShowMore;
