import Nav from "../components/Nav/NavBar/NavBar";
import Steps from "../components/Config/Steps/Step1";
import Main from "../components/Config/Paint/Paint";
import Preview from "../components/Config/Overview/Preview";

function Step1() {
  return (
    <body>
      <Nav />
      <Steps />
      <Main />
      <Preview />
    </body>
  );
}

export default Step1;
