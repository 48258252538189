import "./AboutHome.css";
import image_1 from "../../../media/img/landing-about-1.jpg";
import image_2 from "../../../media/img/landing-about-2.png";
import ButtonAbout from "../../Buttons/ButtonAbout";

function AboutHome() {
  return (
    <section className="about-hero main-2">
      <h4 className="about-title">
        We at Tethys Yachts believe that attention to every detail is key to the
        design and construction of all of our vessels.
      </h4>
      <div className="about-img-container">
        <img className="about-photo-1" src={image_1} alt="img" />
        <img className="about-photo-2" src={image_2} alt="img" />
      </div>
      <p className="about-subtitle body-text-lg">
        Each vessel we produce is handcrafted to the finest detail to ensure its
        quality and safety. We aim to deliver only the best to our demanding
        clients, who, just like us, are passionate about boating.
      </p>
      <ButtonAbout className="about-button" />
      <img className="about-photo-3" src={image_2} alt="img" />
    </section>
  );
}

export default AboutHome;
