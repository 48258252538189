import "./AboutSections.css";
import image from "../../../media/img/persona.png";

function Personas() {
  return (
    <section className="about-container">
      <div className="about-persona-1-container">
        <img
          className="about-persona-1-image"
          src={image}
          alt="img"
        ></img>
        <div className="about-persona-1-content-container">
          <h2 className="about-persona-1-title">Persona 1</h2>
          <p className="about-persona-1-paragraph body-text-lg">
            ABYC, American Boat and Yacht Council, is an organization that has
            been developing and updationg safety for boat buildind and repair
            for 50 years.
          </p>
          <p className="about-persona-1-paragraph body-text-lg">
            Actively involved with the International Standardization
            Organization (ISO) and a leader in shipbuilding education as well as
            certification programs for maritime technicians. We are proud to
            have ABYC seal on our boats.
          </p>
        </div>
      </div>
      <div className="about-persona-2-container">
        <div className="about-persona-2-content-container">
          <h2 className="about-persona-2-title">Persona 2</h2>
          <p className="about-persona-2-paragraph body-text-lg">
            ABYC, American Boat and Yacht Council, is an organization that has
            been developing and updationg safety for boat buildind and repair
            for 50 years.
          </p>
          <p className="about-persona-2-paragraph body-text-lg">
            Actively involved with the International Standardization
            Organization (ISO) and a leader in shipbuilding education as well as
            certification programs for maritime technicians. We are proud to
            have ABYC seal on our boats.
          </p>
        </div>
        <img
          className="about-persona-2-image"
          src={image}
          alt="img"
        ></img>
      </div>
    </section>
  );
}

export default Personas;
