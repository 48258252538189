import React from "react";
import ReactPlayer from "react-player";
import Video from "../../media/video/video-about.mp4";
import "./Video.css";

function VideoAbout() {
  return (
    <section>
      <ReactPlayer
        url={Video}
        width="100vw"
        height="70vh"
        loop="true"
        playing="true"
        volume="0"
      />
    </section>
  );
}

export default VideoAbout;
