import "./HeroAbout.css";

function HeroAbout() {
  return (
    <section className="about-page-hero">
      <h1 className="about-page-hero-title black">Who We Are</h1>
      <div className="about-page-hero-container">
        <p className="about-page-paragraph-1 body-text-lg black">
          We at Tethys Yachts believe that attention to every detail is key to
          the design and construction of all of our vessels. Each vessel we
          produce is handcrafted to the finest detail to ensure its quality and
          safety.
        </p>
        <p className="about-page-paragraph-2 body-text-lg black">
          We aim to deliver only the best to our demanding clients, who, just
          like us, are passionate about boating. We offer state-of-the-art
          technology with charming refined finishes combining beauty and
          funcionality enabling an unforgetable sailing experience.
        </p>
      </div>
    </section>
  );
}

export default HeroAbout;
